/* eslint-disable @typescript-eslint/camelcase */
import Vue from 'vue'
import qs from 'qs'
import VueRouter, { RouteConfig, RawLocation, Route } from 'vue-router'
import { Message, Loading } from 'element-ui'
import axios from '@/utils/ajax'
import { apis } from '@/api'
import Layout from '../views/layout/Layout.vue'
import project from './project'
import projectWorker from './projectWorker'
import devices from './device'
import irrigation from './irrigation'
import soilFertility from './soilFertility'
import soilHumidity from './soilHumidity'
import location from './location'
import monitor from './monitor'
import realtimeTraffic from './realtimeTraffic'
import bracelet from './bracelet'
import trash from './trash'
import inspection from './inspection'
import waterQuality from './waterQuality'
import waterMeter from './waterMeter'
import circuitBreaker from './circuitBreaker'
import smoke from './smoke'
import weatherQuality from './weatherQuality'
import vehicle from './vehicle'
import projectEvaluation from './projectEvaluation'
import screen from './screen'
import insectMonitor from './insectMonitor'
import eventManage from './eventManage'
import rareTrees from './rareTrees'
import ordinarySeedling from './ordinarySeedling'
import serviceFacility from './serviceFacility'
import buildFacilities from './buildFacilities'
import scenery from './scenery'
import intelligentMachine from './intelligentMachine'
import advertising from './advertising'
import scenic from './scenic'
import notice from './notice'
import newOrder from './newOrder'
import sumec from './sumec'
import guideComment from './guideComment'
import carManage from './carManage'
import restaurant from './restaurant'
import theme from './theme'
import visitorInfo from './visitorInfo'
import lostGoods from './lostGoods'
import manholeException from './manholeException'
import manholeManage from './manholeManage'
import manhole from './manhole'
import projectFile from './projectFile'
import plan from './plan'
import scenarios from './scenarios'
import dispatch from './dispatch'
import dispatchMap from './dispatchMap'
import inspectionMonth from './inspectionMonth'
import blackList from './blackList'
import workcard from './workcard'
import blank from './blank'
import plantEstimation from './plantEstimation'
import pondMonitor from './pondMonitor'
import overview from './overview'
import qualitySafety from './qualitySafety'
import engineering from './engineering'
import engineeringEvent from './engineeringEvent'
import inspectionReport from './inspectionReport'
import intelligentControl from './intelligentControl'
import homePage from './homePage'
import material from './material'
import farmingCalendar from './farmingCalendar'
import healthLife from './healthLife'
import parkStrategy from './parkStrategy'
import operationmanage from './operationmanage'
import hydrant from './hydrant'
import pressureMonitor from './pressureMonitor'
import handover from './handover'
import school from './school'
import assetScheduling from './assetScheduling'
import odor from './odor'
import patrol from './patrol'
// 游客服务 -预约管理
import appointManage from './appointManage'
// 游客服务 -客服管理
import customerManage from './customerManage'
// 游客服务 -问卷调查
import questionSurvey from './questionSurvey'
// 智慧厕所
import toilet from './toilet'
import greenhouse from './greenhouse'
import mistForest from './mistForest'
Vue.use(VueRouter)

/**
 * 配置说明:
 * 1.路由配置，父子路由路径以'/'隔开，如：项目列表页'/projectList',详情'/projectList/detail',以此类推。配置重定向或者backUrl的除外
 * 2.'/'符号用做了截取父级路由路径判断，所以非路径隔断不要使用。如：动态路由前面不要带'/'；
 * 3.meta字段说明（非必填）：
 *   1).title：页面头部展示标题；
 *   2).hasQuery及backUrl用作返回判断:
 *     backUrl当无法通过路由截取父级路径时配置;
 *     hasQuery用作判断父级路由是否携带query参数,如果有则从子路由上截取带入父路由路径;
 */
const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/Login.vue'),
    meta: {
      requireLogin: false
    },
    beforeEnter (to, from, next) {
      // 临空项目单点登录
      const params = qs.parse(window.location.search.substring(1))
      if (params.distribute_ticket) {
        const authUrl = 'https://iot.tuya.com/cloud-oauth/authorize'
        window.location.href = `${authUrl}?${qs.stringify({
          client_id: 'ushxtsuf73v7mnp3ukx4', // 生产环境
          redirect_uri: 'https://ipark.gintell.cn/auth', // 生产环境
          // client_id: 'kafvttjfmd8he47hywdn', // 测试环境
          // redirect_uri: 'https://test.user.gintell.cn:9999/auth', // 测试环境
          distribute_ticket: params.distribute_ticket,
          state: 'f08a63931288455fa3690a8e52cd2b5c',
          response_type: 'code',
          scope: 'user'
        })}`
      } else {
        next()
      }
    }
  },
  {

    path: '/loginSSOXz',
    name: 'LoginSSOXz',
    component: () => import('@/views/login/LoginSSO.vue'),
    meta: {
      requireLogin: false
    }
  },
  {

    path: '/loginSSOLk',
    name: 'loginSSOLk',
    component: () => import('@/views/login/LoginSSO.vue'),
    meta: {
      requireLogin: false
    }
  },
  {
    path: '/loginSSORj',
    name: 'loginSSORj',
    component: () => import('@/views/login/LoginSSO.vue'),
    meta: {
      requireLogin: false
    }
  },
  {
    path: '/workbench',
    name: 'workbench',
    component: () => import('@/views/workbench/Index.vue')
  },
  {
    path: '/',
    name: 'Layout',
    component: Layout,
    children: [
      ...project,
      ...projectWorker,
      ...devices,
      ...irrigation,
      ...soilFertility,
      ...soilHumidity,
      ...location,
      ...monitor,
      ...realtimeTraffic,
      ...bracelet,
      ...workcard,
      ...trash,
      ...inspection,
      ...waterQuality,
      ...waterMeter,
      ...circuitBreaker,
      ...smoke,
      ...weatherQuality,
      ...vehicle,
      ...projectEvaluation,
      ...screen,
      ...insectMonitor,
      ...eventManage,
      ...rareTrees,
      ...ordinarySeedling,
      ...serviceFacility,
      ...buildFacilities,
      ...scenery,
      ...intelligentMachine,
      ...advertising,
      ...scenic,
      ...notice,
      ...newOrder,
      ...sumec,
      ...guideComment,
      ...carManage,
      ...restaurant,
      ...theme,
      ...visitorInfo,
      ...lostGoods,
      ...manholeException,
      ...manholeManage,
      ...manhole,
      ...projectFile,
      ...plan,
      ...scenarios,
      ...dispatch,
      ...dispatchMap,
      ...inspectionMonth,
      ...blackList,
      ...blackList,
      ...blank,
      ...plantEstimation,
      ...pondMonitor,
      ...overview,
      ...qualitySafety,
      ...engineering,
      ...engineeringEvent,
      ...inspectionReport,
      ...intelligentControl,
      ...homePage,
      ...material,
      ...farmingCalendar,
      ...parkStrategy,
      ...healthLife,
      ...operationmanage,
      ...hydrant,
      ...pressureMonitor,
      ...handover,
      ...school,
      ...assetScheduling,
      ...odor,
      ...patrol,
      ...appointManage,
      ...customerManage,
      ...questionSurvey,
      ...toilet,
      ...greenhouse,
      ...mistForest,
      {
        path: '/message',
        name: 'message',
        component: () => import('@/views/message/Warning.vue'),
        meta: {
          title: '消息预警'
        }
      },
      {
        path: 'message/detail:id',
        name: 'warningDetail',
        component: () => import('@/views/message/WarningDetail.vue'),
        meta: {
          title: '消息详情'
        }
      },
      {
        path: 'homePage/detail:id',
        name: 'homePageWarningDetail',
        component: () => import('@/views/message/WarningDetail.vue'),
        meta: {
          title: '首页概览-消息详情'
        }
      },
      {
        path: '/record',
        name: 'record',
        component: () => import('@/views/message/Record.vue'),
        meta: {
          title: '消息记录'
        }
      },
      {
        path: '/department',
        name: 'department',
        component: () => import('@/views/department/Department.vue'),
        meta: {
          title: '组织架构'
        }
      },
      {
        path: '/department/add',
        name: 'personnelAdd',
        component: () => import('@/views/department/PersonnelAdd.vue'),
        meta: {
          title: '新增人员'
        }
      },
      {
        path: '/department/update:id',
        name: 'personnelUpdate',
        component: () => import('@/views/department/PersonnelAdd.vue'),
        meta: {
          title: '编辑人员'
        }
      },
      {
        path: '/department/detail:id',
        name: 'personnelDetail',
        component: () => import('@/views/department/PersonnelDetail.vue'),
        meta: {
          title: '人员详情'
        }
      },
      {
        path: '/role',
        name: 'role',
        component: () => import('@/views/role/Role.vue'),
        meta: {
          title: '角色管理'
        }
      },
      {
        path: '/role/add:orderNum',
        name: 'roleAdd',
        component: () => import('@/views/role/RoleAdd.vue'),
        meta: {
          title: '角色新增'
        }
      },
      {
        path: '/role/update:id',
        name: 'roleUpdate',
        component: () => import('@/views/role/RoleAdd.vue'),
        meta: {
          title: '角色编辑'
        }
      },
      {
        path: '/userInfo',
        name: 'userInfo',
        component: () => import('@/views/userinfo/Index.vue'),
        meta: {
          title: '账户信息'
        }
      },
      {
        path: '/404',
        component: () => import('@/views/404.vue'),
        meta: {
          title: '404'
        }
      }
    ]
  },
  {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  routes
})

// 临时修复路由跳转为当前路由报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location: RawLocation) {
  return (originalPush.call(this, location) as unknown as Promise<Route>).catch((err: object) => err) as Promise<Route>
}
// 临时修复路由跳转为当前路由报错问题
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function push (location: RawLocation) {
  return (originalReplace.call(this, location) as unknown as Promise<Route>).catch((err: object) => err) as Promise<Route>
}

// 路由错误处理
router.beforeEach((to, from, next) => {
  if ((to.meta.requireLogin !== undefined && !to.meta.requireLogin) || window.sessionStorage.getItem('token')) {
    // 判断是否需要登录或者是否已经登录,不需要登录或者已经登录正常跳转
    if (to.matched.length === 0) {
      // 跳转404
      next('/404')
    } else {
      next()
    }
  } else {
    Message.error('您还未登录或者登录已过期,请先登录!')
    // 需要登录且未登录,跳转登录页面
    next('/login')
  }
})
export default router
