export interface Apis {
  /** 新增 */
  insertOperating: string;
  /** 编辑 */
  updateOperating: string;
  /** 删除 */
  deleteOperating: string;
  /** 查询详情 */
  selectOperatingByOperatingId: string;
  /** 分页查询 */
  selectOperatingByPage: string;
  /** 导出经营记录 */
  exportOperatingInfoList: string;

  /** 经营月报 -- begin
   * 经营月报列表 */
  selectOperatingReportByPage: string;
   /** 经营月报列表导出 */
  exportOperatingList: string;
  /** 经营月报详情 */
  selectOperatingReportByDate: string;
  /** 经营月报导出 */
  exportOperatingReport: string;
  /** 经营月报 -- end */

  /** 计划汇报/审批 */
  /** 新增 */
  insertPlanReport: string;
  /** 编辑 */
  updatePlanReport: string;
  /** 撤回操作、审批操作（批准、驳回） */
  updateStatusWithdraw: string;
  /** 查询详情 */
  selectPlanReportByReportId: string;
  /** 分页查询列表 */
  selectPlanReportByPage: string;
  /** 导入 */
  importPlanReport: string;
  /** 删除 */
  deletePlanReport: string;

  // 按日期和项目查询考勤图片
  selectClockList: string;

  // 日报分享导出
  selectOperatingShareExport: string;
 }

export const apis: Apis = {
  insertOperating: '/business/operating/insertOperating',
  updateOperating: '/business/operating/updateOperating',
  deleteOperating: '/business/operating/deleteOperating',
  selectOperatingByOperatingId: '/business/operating/selectOperatingByOperatingId',
  selectOperatingByPage: '/business/operating/selectOperatingByPage',
  exportOperatingInfoList: '/business/operating/exportOperatingInfoList',
  selectOperatingReportByPage: '/business/operating/selectOperatingReportByPage',
  exportOperatingList: '/business/operating/exportOperatingList',
  selectOperatingReportByDate: '/business/operating/selectOperatingReportByDate',
  exportOperatingReport: '/business/operating/exportOperatingReport',

  insertPlanReport: '/business/planReport/insertPlanReport',
  updatePlanReport: '/business/planReport/updatePlanReport',
  updateStatusWithdraw: '/business/planReport/updateStatusWithdraw',
  selectPlanReportByReportId: '/business/planReport/selectPlanReportByReportId',
  selectPlanReportByPage: '/business/planReport/selectPlanReportByPage',
  importPlanReport: '/business/planReport/importPlanReport',
  deletePlanReport: '/business/planReport/deletePlanReport',
  selectClockList: '/business/clockHistory/selectClockList',

  selectOperatingShareExport: '/business/operating/selectOperatingShareExport'
}
