// 接口名定义
export interface Apis {
  /** 雾森开关操作 */
  insertSpray: string;
  /** 分页查询雾森操作记录列表 */
  selectSprayByPage: string;
  /** 项目汇总分页 */
  selectSumProjectList: string;
  /** 项目汇总详情 */
  selectSpray: string;
  /** 分页查询雾森控制传感器 */
  selectSprayPage: string;
}

export const apis: Apis = {
  insertSpray: '/device/spray/insertSpray',
  selectSprayByPage: '/device/spray/selectSprayByPage',
  selectSpray: '/business/spray/selectSpray',
  selectSumProjectList: '/business/spray/selectSumProjectList',
  selectSprayPage: '/business/spray/selectSprayPage'
}
