import { apis as user, Apis as userApi } from '@/api/user'
import { apis as devices, Apis as devicesApi } from '@/api/devices'
import { apis as common, Apis as commonApi } from '@/api/common'
import { apis as project, Apis as projectApi } from '@/api/project'
import { apis as worker, Apis as workerApi } from '@/api/worker'
import { apis as role, Apis as roleApi } from '@/api/role'
import { apis as department, Apis as departmentApi } from '@/api/department'
import { apis as personnel, Apis as personnelApi } from '@/api/personnel'
import { apis as irrigation, Apis as irrigationApi } from '@/api/irrigation'
import { apis as irrigationConsume, Apis as irrigationConsumeApi } from '@/api/irrigationConsume'
import { apis as irrigationStatistics, Apis as irrigationStatisticsApi } from '@/api/irrigationStatistics'
import { apis as userInfo, Apis as userInfoApi } from '@/api/userInfo'
import { apis as messageRecord, Apis as messageRecordApi } from '@/api/messageRecord'
import { apis as messageWarning, Apis as messageWarningApi } from '@/api/messageWarning'
import { apis as soilHumidity, Apis as soilHumidityApi } from '@/api/soilHumidity'
import { apis as soilFertility, Apis as soilFertilityApi } from '@/api/soilFertility'
import { apis as location, Apis as locationApi } from '@/api/location'
import { apis as monitor, Apis as monitorApi } from '@/api/monitor'
import { apis as realtimeTraffic, Apis as realtimeTrafficApi } from '@/api/realtimeTraffic'
import { apis as bracelet, Apis as braceletApi } from '@/api/bracelet'
import { apis as trash, Apis as trashApi } from '@/api/trash'
import { apis as inspection, Apis as inspectionApi } from '@/api/inspection'
import { apis as waterQuality, Apis as waterQualityApi } from '@/api/waterQuality'
import { apis as waterMeter, Apis as waterMeterApi } from '@/api/waterMeter'
import { apis as circuitBreaker, Apis as circuitBreakerApi } from '@/api/circuitBreaker'
import { apis as smoke, Apis as smokeApi } from '@/api/smoke'
import { apis as weatherQuality, Apis as weatherQualityApi } from '@/api/weatherQuality'
import { apis as vehicle, Apis as vehicleApi } from '@/api/vehicle'
import { apis as screen, Apis as screenApi } from '@/api/screen'
import { apis as insectMonitor, Apis as insectMonitorApi } from '@/api/insectMonitor'
import { apis as eventManage, Apis as eventManageApi } from '@/api/eventManage'
import { apis as seedling, Apis as seedlingApi } from '@/api/seedling'
import { apis as serviceFacility, Apis as serviceFacilityApi } from '@/api/serviceFacility'
import { apis as buildFacilities, Apis as buildFacilitiesApi } from '@/api/buildFacilities'
import { apis as scenery, Apis as sceneryApi } from '@/api/scenery'
import { apis as intelligentMachine, Apis as intelligentMachineApi } from '@/api/intelligentMachine'
import { apis as curing, Apis as curingApi } from '@/api/curing'
import { apis as record, Apis as recordApi } from '@/api/record'
import { apis as advertising, Apis as advertisingApi } from '@/api/advertising'
import { apis as scenic, Apis as scenicApi } from '@/api/scenic'
import { apis as notice, Apis as noticeApi } from '@/api/notice'
import { apis as newOrder, Apis as newOrderApi } from '@/api/newOrder'
import { apis as sumec, Apis as sumecApi } from '@/api/sumec'
import { apis as guideComment, Apis as guideCommentApi } from '@/api/guideComment'
import { apis as carManage, Apis as carManageApi } from '@/api/carManage'
import { apis as theme, Apis as themeApi } from '@/api/theme'
import { apis as restaurant, Apis as restaurantApi } from '@/api/restaurant'
import { apis as visitor, Apis as visitorApi } from '@/api/visitor'
import { apis as lostGoods, Apis as lostGoodsApi } from '@/api/lostGoods'
import { apis as manholeException, Apis as manholeExceptionApi } from '@/api/manholeException'
import { apis as manholeManage, Apis as manholeManageApi } from '@/api/manholeManage'
import { apis as manhole, Apis as manholeApi } from '@/api/manhole'
import { apis as projectFile, Apis as projectFileApi } from '@/api/projectFile'
import { apis as plan, Apis as planApi } from '@/api/plan'
import { apis as scenarios, Apis as scenariosApi } from '@/api/scenarios'
import { apis as dispatch, Apis as dispatchApi } from '@/api/dispatch'
import { apis as evaluation, Apis as evaluationApi } from '@/api/evaluation'
import { apis as blackList, Apis as blackListApi } from '@/api/blackList'
import { apis as workcard, Apis as workcardApi } from '@/api/workcard'
import { apis as plantEstimated, Apis as plantEstimatedApi } from '@/api/plantEstimated'
import { apis as pondMonitor, Apis as pondMonitorApi } from '@/api/pondMonitor'
import { apis as overview, Apis as overviewApi } from '@/api/overview'
import { apis as qualitySafety, Apis as qualitySafetyApi } from '@/api/qualitySafety'
import { apis as engineering, Apis as engineeringApi } from '@/api/engineering'
import { apis as inspectionReport, Apis as inspectionReportApi } from '@/api/inspectionReport'
import { apis as intelligentControl, Apis as intelligentControlApi } from '@/api/intelligentControl'
import { apis as homePage, Apis as homePageApi } from '@/api/homePage'
import { apis as material, Apis as materialApi } from '@/api/material'
import { apis as calendar, Apis as calendarApi } from '@/api/farmingCalendar'
import { apis as healthLife, Apis as healthLifeApi } from '@/api/healthLife'
import { apis as parkStrategy, Apis as parkStrategyApi } from '@/api/parkStrategy'
import { apis as operationmanage, Apis as operationmanageApi } from '@/api/operationmanage'
import { apis as hydrant, Apis as hydrantApi } from '@/api/hydrant'
import { apis as pressureMonitor, Apis as pressureMonitorApi } from '@/api/pressureMonitor'
import { apis as handover, Apis as handoverApi } from '@/api/handover'
import { apis as school, Apis as schoolApi } from '@/api/school'
import { apis as assetScheduling, Apis as assetSchedulingApi } from '@/api/assetScheduling'
import { apis as odor, Apis as odorApi } from '@/api/odor'
import { apis as patrol, Apis as patrolApi } from '@/api/patrol'
import { apis as appointManage, Apis as appointManageApi } from '@/api/appointManage'
import { apis as customerManage, Apis as customerManageApi } from '@/api/customerManage'
import { apis as questionSurvey, Apis as questionSurveyApi } from '@/api/questionSurvey'
import { apis as toilet, Apis as toiletApi } from '@/api/toilet'
import { apis as greenhouse, Apis as greenhouseApi } from '@/api/greenhouse'
import { apis as mistForest, Apis as mistForestApi } from '@/api/mistForest'
export interface Apis {
  user: userApi;
  common: commonApi;
  project: projectApi;
  worker: workerApi;
  devices: devicesApi;
  role: roleApi;
  department: departmentApi;
  personnel: personnelApi;
  irrigation: irrigationApi;
  irrigationConsume: irrigationConsumeApi;
  irrigationStatistics: irrigationStatisticsApi;
  userInfo: userInfoApi;
  messageRecord: messageRecordApi;
  messageWarning: messageWarningApi;
  soilHumidity: soilHumidityApi;
  soilFertility: soilFertilityApi;
  location: locationApi;
  monitor: monitorApi;
  realtimeTraffic: realtimeTrafficApi;
  bracelet: braceletApi;
  trash: trashApi;
  inspection: inspectionApi;
  waterQuality: waterQualityApi;
  waterMeter: waterMeterApi;
  circuitBreaker: circuitBreakerApi;
  smoke: smokeApi;
  weatherQuality: weatherQualityApi;
  vehicle: vehicleApi;
  screen: screenApi;
  insectMonitor: insectMonitorApi;
  eventManage: eventManageApi;
  seedling: seedlingApi;
  serviceFacility: serviceFacilityApi;
  buildFacilities: buildFacilitiesApi;
  scenery: sceneryApi;
  intelligentMachine: intelligentMachineApi;
  curing: curingApi;
  record: recordApi;
  advertising: advertisingApi;
  scenic: scenicApi;
  notice: noticeApi;
  newOrder: newOrderApi;
  sumec: sumecApi;
  guideComment: guideCommentApi;
  carManage: carManageApi;
  theme: themeApi;
  restaurant: restaurantApi;
  visitor: visitorApi;
  lostGoods: lostGoodsApi;
  manholeException: manholeExceptionApi;
  manholeManage: manholeManageApi;
  manhole: manholeApi;
  projectFile: projectFileApi;
  plan: planApi;
  scenarios: scenariosApi;
  dispatch: dispatchApi;
  evaluation: evaluationApi;
  blackList: blackListApi;
  workcard: workcardApi;
  plantEstimated: plantEstimatedApi;
  pondMonitor: pondMonitorApi;
  overview: overviewApi;
  qualitySafety: qualitySafetyApi;
  engineering: engineeringApi;
  inspectionReport: inspectionReportApi;
  intelligentControl: intelligentControlApi;
  homePage: homePageApi;
  material: materialApi;
  calendar: calendarApi;
  healthLife: healthLifeApi;
  parkStrategy: parkStrategyApi;
  operationmanage: operationmanageApi;
  hydrant: hydrantApi;
  pressureMonitor: pressureMonitorApi;
  handover: handoverApi;
  school: schoolApi;
  assetScheduling: assetSchedulingApi;
  odor: odorApi;
  patrol: patrolApi;
  appointManage: appointManageApi;
  customerManage: customerManageApi;
  questionSurvey: questionSurveyApi;
  toilet: toiletApi;
  greenhouse: greenhouseApi;
  mistForest: mistForestApi;
}

export const apis: Apis = {
  user,
  common,
  project,
  worker,
  devices,
  role,
  department,
  personnel,
  irrigation,
  irrigationConsume,
  irrigationStatistics,
  userInfo,
  messageWarning,
  messageRecord,
  soilHumidity,
  soilFertility,
  location,
  monitor,
  realtimeTraffic,
  bracelet,
  trash,
  inspection,
  waterQuality,
  waterMeter,
  circuitBreaker,
  smoke,
  weatherQuality,
  vehicle,
  screen,
  insectMonitor,
  eventManage,
  seedling,
  serviceFacility,
  buildFacilities,
  scenery,
  intelligentMachine,
  curing,
  record,
  advertising,
  scenic,
  notice,
  newOrder,
  sumec,
  guideComment,
  carManage,
  theme,
  restaurant,
  visitor,
  lostGoods,
  manholeException,
  manholeManage,
  manhole,
  projectFile,
  plan,
  scenarios,
  dispatch,
  evaluation,
  blackList,
  workcard,
  plantEstimated,
  pondMonitor,
  overview,
  qualitySafety,
  engineering,
  inspectionReport,
  intelligentControl,
  homePage,
  material,
  calendar,
  healthLife,
  parkStrategy,
  operationmanage,
  hydrant,
  pressureMonitor,
  handover,
  school,
  assetScheduling,
  odor,
  patrol,
  appointManage,
  customerManage,
  questionSurvey,
  toilet,
  greenhouse,
  mistForest
}
