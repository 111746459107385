import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/mistForest',
    name: 'mistForest',
    meta: {
      title: '智慧雾森',
      icon: '&#xe720;'
    },
    component: () => import('@/views/mistForest/List.vue')
  },
  {
    path: '/mistForest/detail',
    name: 'mistForestDetail',
    meta: {
      title: '详情'
    },
    component: () => import('@/views/mistForest/Detail.vue')
  }
]

export default router
