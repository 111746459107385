import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/greenhouse',
    name: 'greenhouse',
    meta: {
      title: '大棚控制柜'
    },
    component: () => import('@/views/greenhouse/List.vue')
  },
  {
    path: '/greenhouse/detail:projectId',
    name: 'greenhouseDetail',
    component: () => import('@/views/greenhouse/Detail.vue'),
    redirect: '/greenhouseList',
    children: [
      {
        path: '/greenhouseList/detail:projectId/map',
        name: 'greenhouseDetailMap',
        component: () => import('@/views/greenhouse/DetailMap.vue'),
        meta: {
          hasBack: true,
          backUrl: '/greenhouse',
          title: '详情'
        }
      },
      {
        path: '/greenhouseList/detail:projectId/list',
        name: 'greenhouseDetailList',
        component: () => import('@/views/greenhouse/DetailList.vue'),
        meta: {
          title: '详情'
        }
      }
    ]
  },
  {
    path: '/greenhouseList/detail:projectId/map/history:id',
    name: 'greenhouseListHistory',
    component: () => import('@/views/greenhouse/History.vue'),
    meta: {
      title: '控制柜详情'
    }
  },
  {
    path: '/greenhouseList/detail:projectId/map/history:id',
    name: 'greenhouseMapHistory',
    component: () => import('@/views/greenhouse/History.vue'),
    meta: {
      title: '历史数据'
    }
  }
]

export default router
