// 接口名定义
export interface Apis {
  // 获取项目列表
  selectSumProjectList: string;
  selectControlCabinet: string;
  selectControlCabinetById: string;
  insertControlCabinet: string;
  batchInsertControlCabinetConfig: string;
  selectControlCabinetConfigById: string;
}

export const apis: Apis = {
  selectSumProjectList: '/business/controlcabinet/selectSumProjectList',
  selectControlCabinet: '/business/controlcabinet/selectControlCabinet',
  selectControlCabinetById: '/business/controlcabinet/selectControlCabinetById',
  insertControlCabinet: '/device/controlcabinet/insertControlCabinet',
  batchInsertControlCabinetConfig: '/device/controlcabinet/batchInsertControlCabinetConfig',
  selectControlCabinetConfigById: '/device/controlcabinet/selectControlCabinetConfigById'
}
